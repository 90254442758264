import {
  AudiFooterResponse,
  Category,
  ContentHeadless,
  headlessCategoryType,
  headlessLanguageLinkType,
  headlessLinkType,
  headlessSocialMediaLinkType,
  LanguageLink,
  Link,
  SocialMediaType,
} from '../types/audi-footer-response.types';

export function normalizeHeadlessData(headless: ContentHeadless): AudiFooterResponse {
  const categories: Category[] = [];
  headless.fields.Categories.forEach((category: headlessCategoryType) => {
    const subLinks: Link[] = [];
    category.fields.SubLinks.forEach((link: headlessLinkType) => {
      subLinks.push({
        IsOverview: false,
        Type: link.fields.Type,
        Target: link.fields.Target,
        Text: link.fields.Text,
        Url: link.fields.Url,
      });
    });
    categories.push({
      Link: {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        Text: category.fields.CategoryTitle
          ? category.fields.CategoryTitle
          : category.fields.Link.fields.Text,
        Url: '',
        Target: '',
        Type: '',
      },
      SubLinks: subLinks,
    });
  });
  const legalLinks: Link[] = [];
  headless.fields.LegalLinks.forEach((legalLink: headlessLinkType) => {
    legalLinks.push({
      IsOverview: false,
      Type: legalLink.fields.Type,
      Target: legalLink.fields.Target,
      Text: legalLink.fields.Text,
      Url: legalLink.fields.Url,
    });
  });
  const languageLinks: LanguageLink[] = [];
  headless.fields.LanguageLinks.forEach((languageLink: headlessLanguageLinkType, index: number) => {
    languageLinks.push({
      Text: languageLink.fields.Text,
      Url: languageLink.fields.Url,
      IsSelected: index === 0,
    });
  });
  const socialMedia: SocialMediaType[] = [];
  headless.fields.SocialMedia?.forEach((socialMediaItem: headlessSocialMediaLinkType) => {
    socialMedia.push({
      Id: socialMediaItem.fields.Id,
      Link: {
        IsOverview: false,
        Type: 'default',
        Target: socialMediaItem.fields.Target,
        Text: socialMediaItem.fields.Text,
        Url: socialMediaItem.fields.Url,
      },
    });
  });
  return {
    Categories: categories,
    Copyright: headless.fields.Copyright,
    ToTopLabel: headless.fields.ToTopLabel,
    LanguageLinks: languageLinks,
    LegalLinks: legalLinks,
    SocialMedia: socialMedia,
    Disclaimer: headless.fields.Disclaimer,
    ImageUrl: headless.fields.CustomFooterImage?.path,
    ImageText: headless.fields.CustomFooterImageText,
    FooterImageAriaLabel: headless.fields.LegalCopy,
    enableMiniFooter: headless.fields.enableMiniFooter,
  };
}
