import classNames from 'classnames';
import React from 'react';

const footnotesAnchorTextSelector = '.audi-footnote-anchor__text';
const referenceSelector = '.audi-j-footnote-reference';

interface linkType {
  Type?: string;
  Target?: string;
  IsOverview?: boolean;
  Text: string;
  Url: string;
}

export const getClassNamesForLinks: (
  link: linkType,
  countryCode?: string,
  useOneLayer?: boolean,
) => string = (link, countryCode, useOneLayer) => {
  return classNames({
    'audi-cookie-modal-link': link.Type === 'cookie-settings-layer' && countryCode !== 'US',
    'j-action-usabilla': link.Type === 'feedback',
    'nm-j-poa': link.Type === 'poa',
    'one-layer-link': link.Target === '_layer' && useOneLayer,
    'nm-layerLink': link.Target === '_layer' && !useOneLayer,
    'optanon-show-settings': link.Type === 'cookie-settings-layer' && countryCode === 'US',
    'ot-skd-show-settings': link.Type === 'onetrust-cookie-settings',
  });
};

export const getIdNameForLink: (link: linkType) => string | undefined = (link) => {
  if (link && link.Type === 'onetrust-cookie-settings') {
    return 'ot-sdk-btn';
  }
  return undefined;
};

export const getLinkTarget: (link: linkType, useOneLayer?: boolean) => string = (
  link,
  useOneLayer,
) => {
  let target = link.Target || '_self';

  if (!link.Target || link.Target === '_inpage' || (link.Target === '_layer' && !useOneLayer)) {
    target = '_self';
  }

  return target;
};

export function onCookieModalLinkClick(event: React.MouseEvent): void {
  if (typeof window === 'undefined') {
    return;
  }

  event.preventDefault();

  if (!window.Bootstrapper) {
    return;
  }

  if (window.Bootstrapper.gateway && window.Bootstrapper.gateway.openModal) {
    window.Bootstrapper.gateway.openModal();
  } else {
    // eslint-disable-next-line no-console
    console.error('Unable to call Bootstrapper.gateway.openModal');
  }
}

export function extractFootnoteIdFromString(rawKey_: string): string {
  let key = rawKey_;
  const index = key.indexOf('#');

  if (index !== -1) {
    key = key.substr(index + 1);
  }

  return key;
}

export function replaceTextWithFootnoteNumber(element_: HTMLElement, number_: number): void {
  if (!element_.dataset.fixedText && number_ > 0) {
    const textContainer = element_.querySelector(footnotesAnchorTextSelector);
    if (textContainer) {
      textContainer.setAttribute('translate', 'no');
      textContainer.innerHTML = `${number_}`;
    }
  }
}

export function setDataSetonElement({
  element_,
  name,
  id,
  referenceId,
}: {
  element_: HTMLLinkElement;
  name: string;
  id: string;
  referenceId: string;
}): void {
  element_.href = `#${id}`;
  element_.dataset[name] = referenceId;
}

export const loopOverReferences = (
  htmlString: string,
  callBackPerId: (id: string, ref?: HTMLLinkElement) => void,
): string => {
  const div = document.createElement('div');
  div.innerHTML = htmlString;
  const allReferencesFromString = div.querySelectorAll(referenceSelector);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  allReferencesFromString.forEach((reference: HTMLLinkElement) => {
    const footnoteIDFromHref = extractFootnoteIdFromString(reference.href);
    const footnoteIDFromDataAttribute = reference.dataset.referenceId
      ? extractFootnoteIdFromString(reference.dataset.referenceId)
      : undefined;
    const footnoteID = footnoteIDFromDataAttribute || footnoteIDFromHref;
    callBackPerId(footnoteID, reference);
  });

  return div.innerHTML;
};
